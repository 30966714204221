<template>
  <div class="container sticky-bottom">
    <div class="d-flex flex-wrap justify-content-between m-4">
      <a href="mailto:hello@theskinroutine.com">hello@theskinroutine.com</a>
      <div class="text-nowrap pe-4">
        <a href="http://www.instagram.com/the_skinroutine" target="_blank">
          <i class="bi bi-instagram fs-4" alt="instagram"></i>
        </a>
        <a href="http://www.twitter.com/the_skinroutine" target="_blank">
          <i class="bi bi-twitter fs-4 ms-4" alt="twitter"></i>
        </a>
        <a href="http://www.pinterest.com/the_skinroutine" target="_blank">
          <i class="bi bi-pinterest fs-4 ms-4" alt="pinterest"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  background-color: #FBFAF4;
  border-top: 1px solid #101518;
}
</style>
